input,
select,
textarea {
  width: 100%;
  /* max-width: 500px; */
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  background-color: #f4f4f4;
}
textarea {
  height: 90%;
}
input[type="checkbox"] {
  max-width: 50px;
}
label {
  font-size: 1.2rem;
  /* font-weight: 600; */
  color: var(--primaryColor);
  padding-top: 0.3rem;
}

input[type="submit"],
input[type="button"] {
  font-size: 1.2rem;
  font-weight: 600;
  width: 100%;
  /* max-width: 500px; */
  background-color: var(--primaryColor);
  color: #f4f4f4;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
button {
  outline: none;
  border: none;
}
input[type="submit"]:hover {
  /* background-color: var(--blueTextHover); */
}
.intl-tel-input {
  display: flex;
  margin-top: 5px;
  /* padding-top: 5px; */
}
.register-form {
  padding-top: 3rem;
  max-width: "800px";
  color: "#1b2029";
}
.register-form .tele {
  width: 100%;
  max-width: 500px;
  /* padding: 12px 20px;
  margin: 8px 0; */
  display: inline-block;
}

.de-video-container {
  display: none;
  top: 0%;
  left: 0%;
  height: 100vh;
  width: 100%;
  overflow: hidden;
}
.form-responsive {
  width: 100%;
  height: 100vh;
}

.de-video-content {
  color: var(--secondaryColor);
  padding: 1rem 2rem;
  text-shadow: 0.5px 0.5px #ccc;
  background: transparent;
  /* width: 100%; */
  position: absolute;
  z-index: 10;
}
.de-video-content img {
  /* filter: brightness(0) invert(0); */
  /* width: 250px; */
}
.video-title {
  padding-top: 7rem;
}
.video-title img {
  width: 320px;
}
.video-title h1 {
  font-size: 3.7rem;
  font-weight: 900;
  margin-top: 0;
  margin-bottom: 0;
}
.nav-register {
  /* background: var(--primaryColor); */
  /* background: url("https://toplink.weforum.org/assets/images/earth-image-mobile.jpg"); */
  background-position: 50%;
  position: fixed;
  display: block;
  text-align: center;
  /* top: 0;
  left: 0; */
  z-index: 100;
  width: 100%;
  padding: 1rem;
}
@media screen and (min-width: 700px) {
  .de-video-container {
    display: inline;
  }
  .nav-register {
    display: none;
  }
  .form-responsive {
    overflow: scroll;
  }
}
@media screen and (min-width: 1200px) {
  .intl-tel-input {
    margin-top: 7px;
  }
  .video-title h1 {
    font-size: 5rem;
    margin-top: 0;
    margin-bottom: 0;
  }
  .register-form {
    padding: 0 5rem;
  }
  .video-title img {
    width: 470px;
  }
  .de-video-content {
    padding: 1rem 5rem;
  }
}
