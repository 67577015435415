$transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
$blueText: #222833;
/* method */
$method-shadow: 0 0.6px 0.6px rgba(0, 0, 0, 0.12),
  0 0.6px 0.6px rgba(0, 0, 0, 0.24);
$method-shadow-hover: 0 2px 3px rgba(0, 0, 0, 0.15),
  0 1.5px 1.5px rgba(0, 0, 0, 0.11);

.method {
  transition: $transition;
  box-shadow: $method-shadow;
  background: #f4f4f4;
  //margin: 20px 10px;
  cursor: pointer;
  &:hover {
    box-shadow: $method-shadow-hover;
  }
}

/* Selectable */
$check-size: 15px;
$selected-color: $blueText;

.method .selectable {
  position: relative;
  transition: $transition;
  border: 3px solid transparent;
  overflow: hidden;
  @media screen and (min-width:900px) {
    .ribbon {
      font-size: 0.9rem;
      line-height: 15px;

      bottom: 10px;
      left: -50px;
    }
  }
  .ribbon {
    background-color: $blueText;
    width: 150px;
    position: absolute;
    bottom: 10px;
    left: -50px;
    text-align: center;
    line-height: 12px;
    padding: 1px;
    letter-spacing: 1px;
    color: #f4f4f4;
    font-size: 0.8rem;
    font-weight: 500;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
  }
  .check {
    transition: $transition;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;
    width: $check-size;
    height: $check-size;
    &:before {
      transition: $transition;
      content: "";
      border: $check-size solid;
      border-color: transparent;
      position: absolute;
      top: 0;
      right: 0;
      z-index: -1;
    }
    .checkmark {
      display: block;
      font: $check-size sans-serif;
      line-height: $check-size;
      text-align: center;
      color: transparent;
    }
  }

  &.selected {
    border-color: $selected-color;
    .check {
      &:before {
        border-color: $selected-color $selected-color rgba(0, 0, 255, 0)
          rgba(255, 0, 0, 0);
      }
      .checkmark {
        color: #f4f4f4;
      }
    }
  }
}

.payment-method {
  .title {
    font-size: 1.6rem;
    max-width: 150px;
    text-align: center;
  }
  .price {
    text-align: center;
    text-decoration: line-through;
    //margin-bottom: 0;
  }
  .new-price {
    font-size: 1.2rem;
    font-weight: 600;
    //margin-top: 0;
    text-align: center;
  }
  @media screen and (max-width:780px) {
    .title {
      font-size: 1.3rem;
    }
    padding: 5px;
  }
  @media screen and (max-width: 460px) {
    .title {
      font-size: 1.2rem;
    }
    .new-price {
      font-size: 1.1rem;
    }
    padding: 5px;
  }
  padding: 12px;
}

.column {
  & > .title {
    text-align: center;
  }

  width: 100%;
  display: block;
  // max-width: 500px;
}
